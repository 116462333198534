import {
  Component,
  Input,
  SecurityContext,
  ElementRef,
  AfterViewInit,
  ViewEncapsulation,
  OnInit,
  Optional,
} from '@angular/core';
import {
  Options,
  documentToHtmlString,
} from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS, INLINES, Hyperlink } from '@contentful/rich-text-types';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { DataLayerService } from '@/services/data-layer.service';
import { SectionService } from '@/services/section.service';
import { PlatformService } from '@/services/platform.service';

import { IRichTextRenderer } from './rich-text-renderer.types';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'nx-rich-text-renderer',
  templateUrl: './rich-text-renderer.component.html',
  providers: [SectionService],
  styleUrls: ['./rich-text-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextRendererComponent
  implements IRichTextRenderer, AfterViewInit, OnInit
{
  constructor(
    private sanitizer: DomSanitizer,
    private dataLayer: DataLayerService,
    private element: ElementRef<HTMLElement>,
    private section: SectionService,
    private platform: PlatformService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  // REQUIRED INPUTS
  @Input() text!: IRichTextRenderer['text'];

  // PROPERTIES
  public html!: SafeHtml;

  // METHODS
  /**
   * Transforms the rich text into HTML
   */
  public setHtml(): void {
    const options: Options = {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, next) =>
          `<h1 class="equis-h1-semibold">${next(node.content)}</h1>`,
        [BLOCKS.HEADING_2]: (node, next) =>
          `<h2 class="equis-h2-semibold">${next(node.content)}</h2>`,
        [BLOCKS.HEADING_3]: (node, next) =>
          `<h3 class="equis-h3-semibold">${next(node.content)}</h3>`,
        [BLOCKS.HEADING_4]: (node, next) =>
          `<h4 class="equis-h4-semibold">${next(node.content)}</h4>`,
        [BLOCKS.PARAGRAPH]: (node, next) =>
          `<p class="equis-body-1">${next(node.content)}</p>`,
        [BLOCKS.QUOTE]: (node, next) =>
          `<blockquote>${next(node.content)}</blockquote>`,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const asset = node.data['target'];
          if (asset.fields.file.contentType.includes('image/')) {
            // Handle images and render an <img> element
            return `<img src="${node.data['target'].fields.file.url}?fm=webp"
            height="${node.data['target'].fields.file.details.image.height}"
            width="${node.data['target'].fields.file.details.image.width}"
            alt="${node.data['target'].fields.description}" />`;
          }

          if (asset.fields.file.contentType === 'video/mp4') {
            // Handle video assets
            return `
              <video controls width="100%" height="auto">
                <source src="${asset.fields.file.url}" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            `;
          }

          // Handle other embedded assets or unsupported assets
          return '';
        },

        [BLOCKS.UL_LIST]: (node, next) =>
          `<ul class="list unordered-list">${next(node.content)}</ul>`,
        [BLOCKS.OL_LIST]: (node, next) =>
          `<ol class="list ordered-list">${next(node.content)}</ol>`,
        [INLINES.HYPERLINK]: (node, next) => {
          const text = next(node.content);
          const uri = this.platform.getAbsoluteURL(
            (node as Hyperlink).data.uri
          );

          const host = new URL(environment.HOST_URL).host;
          const isSameDomain = new URL(uri).hostname.includes(host);
          return `<a href="${uri}" class="equis-body-1-link" ${
            !isSameDomain && 'target="_blank"'
          }>${text}</a>`;
        },
      },
      renderMark: {
        [MARKS.BOLD]: (text) => `<b class="equis-body-1-medium">${text}</b>`,
        [MARKS.ITALIC]: (text) => `<i class="italic">${text}</i>`,
        [MARKS.UNDERLINE]: (text) => `<span class="underline">${text}</span>`,
      },
    };

    const htmlString = documentToHtmlString(this.text, options);
    this.html = this.sanitizer.sanitize(SecurityContext.HTML, htmlString) ?? '';
  }

  /**
   * Add event for GTM push in contact links
   * @param type Type of the link to add the event
   */
  addContactPush(type: 'tel' | 'mailto') {
    const anchors = this.element.nativeElement.querySelectorAll('a');
    const regex = new RegExp(`^${type}:.+$`);

    anchors.forEach((a) => {
      let experiment!: AmplitudeExperiment;
      if (this.variantContainerComponent) {
        const variant = this.variantContainerComponent.getVariant();
        const key = this.variantContainerComponent.data.experiment.key;
        experiment = { key, variant };
      }

      // It's a phone or email link
      if (regex.test(a.href)) {
        // Add event for push
        a.addEventListener('click', () =>
          this.dataLayer.push(
            {
              eventName: 'contact',
              eventParams: {
                component: 'informacion destacada',
                element: a.text,
                action: 'click',
                channel: type === 'tel' ? 'telefono' : 'email',
                section: 'content',
              },
            },
            // Force 'content' section name
            this.section.sectionName,
            experiment
          )
        );
      }
    });
  }

  // HOOKS
  ngOnInit(): void {
    this.setHtml();
  }
  ngAfterViewInit(): void {
    if (!this.platform.isServer) {
      this.addContactPush('tel');
      this.addContactPush('mailto');
    }
  }
}
