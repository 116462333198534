import { Component, HostBinding, Input, Optional } from '@angular/core';

import { IClickeableContainer } from './clickeable-container.types';
import { PlatformService } from '../../../services/platform.service';
import { SectionService } from '../../../services/section.service';
import { DataLayerService } from '../../../services/data-layer.service';
import { NX23CardFields } from '../../../common/types/content-types';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';

@Component({
  selector: 'nx-clickeable-container',
  templateUrl: './clickeable-container.component.html',
  styleUrls: ['./clickeable-container.component.scss'],
})
export class ClickeableContainerComponent implements IClickeableContainer {
  constructor(
    public platform: PlatformService,
    private section: SectionService,
    private dataLayer: DataLayerService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}
  // REQUIRED INPUTS
  @Input() data!: NX23CardFields;

  @HostBinding('class') get class() {
    return 'clickeable-container';
  }

  // PROPERTIES
  get isClickeable() {
    return !!this.data?.url;
  }

  public pushClick(event: Event): void {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    event.stopImmediatePropagation();
    if (this.data.url)
      this.dataLayer.push(
        this.data.dataLayer,
        this.section.sectionName,
        experiment
      );
  }
}
