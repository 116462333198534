import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { IVideo } from './video.types';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'nx-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VideoComponent implements IVideo {
  constructor(
    public sanitizer: DomSanitizer,
    private platform: PlatformService
  ) {}
  @Input() data!: IVideo['data'];

  get sourceType() {
    if (this.data.embed.includes('tiktok.com')) {
      return 'tiktok';
    }

    return 'default';
  }

  get paddingClass() {
    const className = {
      'Aumentar espaciado superior': 'padding_top',
      'Aumentar espaciado inferior': 'padding_button',
      'Aumentar espaciado superior e inferior': 'padding_both',
    };
    return className[this.data?.padding] ?? null;
  }

  get origin() {
    if (this.platform.isServer) return '';
    return location.origin;
  }
}
