import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import type { ITable } from './table.types';

@Component({
  selector: 'nx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements ITable {
  constructor() {}
  @Input() data!: ITable['data'];
}
