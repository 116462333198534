import { Component, Input, OnInit, Optional } from '@angular/core';
import { INavbarTab } from './navbar-tab.types';
import { ActiveTabService } from '@/sections/navbar/active-tab.service';
import { PlatformService } from '../../../../../../services/platform.service';
import { DataLayerService } from '../../../../../../services/data-layer.service';
import { NX23NavbarTab } from '../../../../../../common/types/content-types';
import { AmplitudeExperiment } from '../../../../../../common/types/data-layer';
import { VariantContainerComponent } from '../../../../variant-container/variant-container.component';
import { SectionService } from '../../../../../../services/section.service';

@Component({
  selector: 'nx-navbar-tab',
  templateUrl: './navbar-tab.component.html',
  styleUrls: ['./navbar-tab.component.scss'],
})
export class NavbarTabComponent implements INavbarTab, OnInit {
  constructor(
    private tabService: ActiveTabService,
    public platform: PlatformService,
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  // REQUIRED INPUTS
  @Input() data!: NX23NavbarTab;

  // PROPERTIES
  isActive = false;

  // HOOKS
  ngOnInit() {
    this.isActive =
      this.tabService.activeTab.fields.url === this.data.fields.url;
  }

  // clickTab() {
  //   this.dataLayer.push(this.fields.dataLayer, '', undefined);
  // }

  clickTab(tab: NX23NavbarTab) {
    let experiment!: AmplitudeExperiment;
    if (this.variantContainerComponent) {
      const variant = this.variantContainerComponent.getVariant();
      const key = this.variantContainerComponent.data.experiment.key;
      experiment = { key, variant };
    }

    this.dataLayer.push(
      tab.fields.dataLayer,
      this.section.sectionName,
      experiment
    );
  }
}
