import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PageService } from '@/services/page.service';
import { ComponentInjectorService } from '@/tooling/component-injector/component-injector.service';
import { ComponentInjectorComponent } from '../component-injector/component-injector.component';
import { tap } from 'rxjs/internal/operators/tap';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IEnviroment } from '../../../common/types/enviroment';
import { PlatformService } from '../../../services/platform.service';
import {
  catchError,
  distinctUntilChanged,
  Observable,
  of,
  retry,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { IRequestHeaders } from '../../../common/types/request-headers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
declare const amplitude: any;
const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'nx-page-builder',
  templateUrl: './page-builder.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBuilderComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  @ViewChild('component', { read: ViewContainerRef, static: true })
  component!: ViewContainerRef;

  constructor(
    private readonly service: ComponentInjectorService,
    private readonly pageService: PageService,
    private readonly cdr: ChangeDetectorRef,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    private http: HttpClient,
    @Optional()
    @Inject('enviromentFromVault')
    public enviroment: IEnviroment,
    @Optional()
    @Inject('headers')
    private headers: IRequestHeaders & { [key: string]: string },
    @Optional()
    @Inject('experimentDeviceId')
    public experimentDeviceId: string,
    @Optional() @Inject(REQUEST) private request: any
  ) {
    if (this.platform.isServer) {
      this.transferState.set(enviromentState, this.enviroment);
      this.setHeaders();
      this.exposureEvent().subscribe();
    }
  }

  ngOnInit(): void {
    this.pageService.sections
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        tap(() => this.component.clear()),
        tap((data) => {
          let index = 0;
          for (let i in data) {
            if (!this.service.canInject(data[i])) {
              continue;
            }
            const ref = this.component.createComponent(
              ComponentInjectorComponent,
              {
                index: Number(index),
              }
            );
            ref.setInput('component', data[i]);
            index += 1;
          }
        }),
        tap(() => this.cdr.markForCheck())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setHeaders = (): void => {
    const headersState = makeStateKey<IRequestHeaders>('requestHeaders');
    const getValueOf = (key: string) =>
      new TextDecoder('utf-8').decode(
        new Uint8Array(
          this.headers?.[key]?.split('').map((c: string) => c.charCodeAt(0))
        )
      );

    const data: IRequestHeaders = {};

    if (getValueOf('region')) data.region = getValueOf('region');
    if (getValueOf('city')) data.city = getValueOf('city');
    if (getValueOf('country')) data.country = getValueOf('country');

    this.transferState.set(headersState, data);
  };

  /**
   *
   * CREATED ONLY TO DEBUG AMPLITUDE
   */
  private exposureEvent(): Observable<Object> {
    if (
      ['cdn-fpw', '.well-known', 'assets'].some((path) =>
        this.request._parsedUrl.pathname.includes(path)
      ) ||
      !this.headers?.['true-client-ip']
    ) {
      return of();
    }

    const event: any = {
      event_type: 'nxcom_ssr_pageview',
      device_id: this.experimentDeviceId,
      ip: this.headers?.['true-client-ip'],
      event_properties: {
        ...this.getAddress(),
        ...this.getCampaignParams(),
        user_agent: this.headers?.['user-agent'],
        sec_ch_ua_platform: this.headers?.['sec-ch-ua-platform'],
        sec_ch_ua_mobile: this.headers?.['sec-ch-ua-mobile'],
        path: this.request._parsedUrl.pathname,
        pageUrl: environment.HOST_URL + this.request.originalUrl,
      },
    };

    if (this.getCampaignParams()?.['userId']) {
      event.user_id = this.getCampaignParams()?.['userId'];
    }

    return this.http
      .post(environment.AMPLITUDE_ANALYTICS_URL, {
        api_key: this.enviroment.AMPLITUDE_ANALYTICS_KEY,
        events: [event],
      })
      .pipe(
        take(1),
        retry(1),
        catchError((e) => {
          throw e;
        })
      );
  }

  private getAddress = () => {
    const data: any = {};
    const parse = (value: string) =>
      new TextDecoder('utf-8').decode(
        new Uint8Array(value?.split('').map((c: string) => c.charCodeAt(0)))
      );

    if (this.headers?.region) data.backend_region = parse(this.headers.region);
    if (this.headers?.city) data.backend_city = parse(this.headers.city);
    if (this.headers?.country)
      data.backend_country = parse(this.headers.country);

    return data;
  };

  private getCampaignParams = () => {
    const pathUrl = this.request.originalUrl?.split('?')[1];
    if (!pathUrl) return;

    const obj: { [key: string]: string } = {};

    new URLSearchParams(pathUrl).forEach((value, key) => {
      if (!value || !key) return;
      if (!['utm_campaign', 'utm_source', 'utm_medium', 'userId'].includes(key))
        return;
      obj[key] = value;
    });

    return obj;
  };
}
