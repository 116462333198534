import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  concatMap,
  finalize,
  map,
  of,
  retry,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { environment } from '@/environment';
import type { IVariantContainer } from './variant-container.types';
import { ContentType } from '../../../common/types/contentful';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { PlatformService } from '../../../services/platform.service';
import { IEnviroment } from '../../../common/types/enviroment';
import { IRequestHeaders } from '../../../common/types/request-headers';

const experimentDeviceIdState = makeStateKey<string | null>(
  'experiment_device_id'
);
const experimentUserIdState = makeStateKey<string | null>('experiment_user_id');
const enviromentState = makeStateKey<IEnviroment>('enviroment');

@Component({
  selector: 'app-variant-container',
  templateUrl: './variant-container.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VariantContainerComponent
  implements IVariantContainer, OnInit, OnDestroy
{
  @Input() data!: IVariantContainer['data'];
  public field?: ContentType;
  private destroyed$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private platform: PlatformService,
    private readonly transferState: TransferState,
    @Optional()
    @Inject('headers')
    private headers: IRequestHeaders
  ) {}

  ngOnInit() {
    if (!this.data.experiment?.key) return;
    const fieldState = makeStateKey<ContentType>(this.data.experiment.key);

    this.executeExperiment(fieldState);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private executeExperiment = (fieldState: StateKey<ContentType>) => {
    this.getExperiment()
      .pipe(
        takeUntil(this.destroyed$),
        concatMap((variantResult) => {
          const field = this.setField(variantResult!, fieldState);
          if (this.platform.isServer || variantResult === 'off') return of();

          return this.exposureEvent(
            this.transferState.get(experimentDeviceIdState, null)!,
            this.data.experiment.key,
            field?.variant
          );
        }),
        catchError((e) => {
          throw e;
        }),
        retry(2),
        finalize(() => this.changeDetector.detectChanges())
      )
      .subscribe({ error: () => (this.field = undefined) });
  };

  private getVariantField = (variant: string) => {
    return this.data.variants.find(
      (data: ContentType) => data.sys.id === this.data.meta[variant]
    );
  };

  private getExperiment = () => {
    const variantResultState = makeStateKey<string>(
      `variantResultState_${this.data.experiment.key}`
    );

    const result = this.transferState.get(variantResultState, null)!;
    if (!result) return this.fetchExperimentResult();

    return of(result);
  };

  private fetchExperimentResult = () => {
    const { AMPLITUDE_EXPERIMENT_TOKEN: Authorization } =
      this.transferState.get(enviromentState, null) as IEnviroment;

    const params: {
      device_id: string;
      flag_key: string;
      context?: string;
      user_id?: string;
    } = {
      device_id: this.transferState.get(experimentDeviceIdState, null)!,
      flag_key: this.data.experiment.key,
    };

    if (this.transferState.get(experimentUserIdState, null))
      params.user_id = this.transferState.get(experimentUserIdState, null)!;

    const userIdByParams = (this.getCampaignParams() as any)?.userId;
    if (userIdByParams) {
      params.user_id = userIdByParams;
      this.transferState.set(experimentUserIdState, userIdByParams);
    }

    const context = { ...this.getAddress(), ...this.getCampaignParams() };
    if (Object.keys(context).length !== 0)
      params.context = JSON.stringify({ user_properties: context });

    const variantResultState = makeStateKey<string>(
      `variantResultState_${this.data.experiment.key}`
    );

    return this.http
      .get<{
        [key: string]: {
          key?: string;
        };
      }>(environment.AMPLITUDE_EXPERIMENT_URL, {
        params,
        headers: {
          Authorization,
        },
      })
      .pipe(
        map((response) => {
          return response[this.data.experiment.key]?.key ?? 'off';
        }),
        tap((experimentAsigned) =>
          this.transferState.set(variantResultState, experimentAsigned)
        )
      );
  };

  private setField = (
    variantResult: string,
    fieldState: StateKey<ContentType>
  ) => {
    const field = this.getVariantField(
      variantResult === 'off' ? 'control' : variantResult
    )!;
    const variantState = makeStateKey<string>(
      `variant_${this.data.experiment.key}`
    );
    this.transferState.set(variantState, variantResult);
    this.transferState.set(fieldState, field);
    this.field = field;
    return {
      field,
      variant: variantResult,
    };
  };

  public getVariant(): string {
    const variantState = makeStateKey<string>(
      `variant_${this.data.experiment.key}`
    );
    return this.transferState.get(variantState, '');
  }

  private exposureEvent = (
    deviceId: string,
    flagKey: string,
    variant: string
  ) => {
    const { AMPLITUDE_ANALYTICS_KEY: api_key } = this.transferState.get(
      enviromentState,
      null
    ) as IEnviroment;

    const event: any = {
      event_type: '$exposure',
      device_id: deviceId,
      event_properties: {
        flag_key: flagKey,
        variant,
      },
    };

    if (this.transferState.get(experimentUserIdState, null))
      event.user_id = this.transferState.get(experimentUserIdState, null)!;

    return this.http.post(environment.AMPLITUDE_ANALYTICS_URL, {
      api_key,
      events: [event],
    });
  };

  private getCampaignParams = () => {
    const STATE_KEY_URL = makeStateKey<string>('');
    const pathUrl = this.transferState.get(STATE_KEY_URL, null)?.split('?')[1];
    if (!pathUrl) return;

    const obj: { [key: string]: string } = {};

    new URLSearchParams(pathUrl).forEach((value, key) => {
      if (!value || !key) return;
      if (!['utm_campaign', 'utm_source', 'utm_medium', 'userId'].includes(key))
        return;
      obj[key] = value;
    });

    return obj;
  };

  private getAddress = () => {
    const headersState = makeStateKey<IRequestHeaders>('requestHeaders');
    const headers = this.transferState.get(headersState, this.headers);
    const data: any = {};

    if (headers?.region) data.backend_region = headers.region;
    if (headers?.city) data.backend_city = headers.city;
    if (headers?.country) data.backend_country = headers.country;

    return data;
  };
}
