<equis-card>
  <div class="padding flex-column">
    <div class="header">
      <p class="equis-h4-medium capitalize">{{ data.name }}</p>
      <equis-tag *ngIf="getBadget" [text]="terminalTag"></equis-tag>
    </div>

    <!-- DESKTOP -->
    <ng-container *ngIf="!isMobile">
      <p
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.address.full_address"
      >
        {{ data.address.full_address }}
      </p>
      <p
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.opening_hours"
      >
        {{ data.opening_hours }}
      </p>
    </ng-container>

    <!-- MOBILE -->
    <ng-container *ngIf="isMobile">
      <p
        equisTooltip
        tooltipDirection="top"
        [tooltipContent]="data.address.full_address"
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.address.full_address"
      >
        {{ data.address.full_address }}
      </p>
      <p
        equisTooltip
        tooltipDirection="top"
        [tooltipContent]="data.opening_hours"
        class="equis-body-2 text-with-ellipsis capitalize"
        [title]="data.opening_hours"
      >
        {{ data.opening_hours }}
      </p>
    </ng-container>

    <div
      class="flex-column boolean-properties"
      *ngFor="let product of getProducts"
    >
      <nx-branch-boolean-property
        [accepts]="product.active"
        [text]="product.prod_nombre"
      ></nx-branch-boolean-property>
    </div>
  </div>
</equis-card>
