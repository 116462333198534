import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import type { IBlogArticles } from './blog-articles.types';
import { finalize, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContentType } from '../../../common/types/contentful';
import { IPaginatorBigData } from '@tn-equis/core/components/paginator-big';
import { PlatformService } from '../../../services/platform.service';
import { makeStateKey, TransferState } from '@angular/platform-browser';

const blogArticlesState = makeStateKey<BlogArticles>('blog_articles_state');

type BlogArticles = {
  total: number;
  skip: number;
  limit: number;
  items: ContentType<any>[];
};

@Component({
  selector: 'nx-blog-articles',
  templateUrl: './blog-articles.component.html',
  styleUrls: ['./blog-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogArticlesComponent implements IBlogArticles, OnInit {
  constructor(
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private transferState: TransferState,
    public platform: PlatformService
  ) {}
  @Input() data!: IBlogArticles['data'];
  @ViewChild('section') public section!: ElementRef;
  public articles?: BlogArticles;
  public currentPage = 1;
  private pageLimit = 12;

  ngOnInit(): void {
    this.getArticles(this.currentPage - 1).subscribe();
    if (!this.platform.isServer)
      this.articles = this.transferState.get(blogArticlesState, null)!;
  }

  private getArticles = (skip: number) => {
    const url = this.data.category
      ? `blog/${this.data.category}/page/${skip}`
      : `blog/page/${skip}`;

    return this.http.get<BlogArticles>(this.platform.getApiURL(url)).pipe(
      map((articles) => {
        articles.items = articles.items.map((article) => {
          // set default sizes
          const image =
            article.fields['previewImage'].fields.file.details.image;
          image.width = 500;
          image.height = 200;

          return {
            ...article,
            fields: {
              ...article.fields,
              image: article.fields['previewImage'],
              description: article.fields['pageDescription'],
              url: '/' + article.fields['slug'],
              actionsList: [this.generateClickToAction(article.fields)],
            },
          };
        });

        return articles;
      }),
      tap((articles) => {
        if (this.platform.isServer) {
          this.transferState.set(blogArticlesState, articles);
        }
        this.articles = articles;
      }),
      finalize(() => this.changeDetectorRef.detectChanges())
    );
  };

  public changePage = (event: IPaginatorBigData) => {
    if (this.calculateTotalPages === 1) return;

    this.currentPage = event.currentPage;
    this.getArticles(
      this.pageLimit * event.currentPage - this.pageLimit
    ).subscribe();
    this.section.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  };

  private generateClickToAction = (fields: { slug: string }) => ({
    sys: {
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'nx23Link',
        },
      },
    },
    fields: {
      url: `/${fields.slug}`,
      label: 'Leer más',
      style: 'Transparente con bordes',
      opensNewTab: false,
      dataLayer: {
        eventName: 'ui_interaction',
        eventParams: {
          action: 'click',
          element: 'leer mas',
          section: 'content',
          component: 'activa una cuenta que te da plata siempre',
          link_type: 'inbound link',
        },
      },
    },
  });

  get calculateTotalPages() {
    return Math.ceil(this.articles!.total / this.pageLimit);
  }
}
