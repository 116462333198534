import { Component, Input } from '@angular/core';

import { INavbarTabs } from './navbar-tabs.types';

@Component({
  selector: 'nx-navbar-tabs',
  templateUrl: './navbar-tabs.component.html',
  styleUrls: ['./navbar-tabs.component.scss'],
})
export class NavbarTabsComponent implements INavbarTabs {
  constructor() {}
  @Input() data!: INavbarTabs['data'];
}
