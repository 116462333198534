import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  Optional,
} from '@angular/core';
import { ICardVideo } from './card-video.types';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../../directives/directives.module';
import {
  EquisIconContainerModule,
  EquisIconContainerSize,
} from '@tn-equis/core/components/icon-container';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { AmplitudeExperiment } from '../../../common/types/data-layer';
import { VariantContainerComponent } from '../../sections/variant-container/variant-container.component';
import { SectionService } from '../../../services/section.service';
import { DataLayerService } from '../../../services/data-layer.service';

@Component({
  selector: 'nx-card-video',
  templateUrl: './card-video.component.html',
  styleUrls: ['./card-video.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DirectivesModule, EquisIconContainerModule],
})
export class CardVideoComponent {
  @Input() data!: ICardVideo['data'];
  @Input() id!: string;
  public source = new BehaviorSubject<SafeResourceUrl | null>(null);
  public iconSize = EquisIconContainerSize.EXTRA_EXTRA_LARGE;

  constructor(
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private dataLayer: DataLayerService,
    private section: SectionService,
    @Optional() public variantContainerComponent: VariantContainerComponent
  ) {}

  private dialog?: HTMLDialogElement;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (!this.dialog) return;
    this.actionDialog('close');
  }

  private clickListener = (event: any) => {
    if (!event.target?.contains(this.dialog)) return;
    this.actionDialog('close');
  };

  public actionDialog(action: 'open' | 'close') {
    this.changeDetectorRef.detectChanges();
    this.dialog = document.getElementById(this.id) as HTMLDialogElement;

    const source = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.embed +
        '?modestbranding=1&autoplay=1&enablejsapi=1&feature=oembed&origin=' +
        location.origin
    );

    if (action === 'open') {
      this.dialog!.showModal();
      this.source.next(source);
      this.dialog!.addEventListener('click', this.clickListener);

      let experiment!: AmplitudeExperiment;
      if (this.variantContainerComponent) {
        const variant = this.variantContainerComponent.getVariant();
        const key = this.variantContainerComponent.data.experiment.key;
        experiment = { key, variant };
      }

      this.dataLayer.push(
        {
          eventName: 'view_modal',
          eventParams: {
            action: 'open',
            element: 'reproductor',
            section: this.section.sectionName,
          },
        },
        this.section.sectionName,
        experiment
      );
      return;
    }

    this.dialog?.close();
    this.dialog?.removeEventListener('click', this.clickListener);
    this.source.next(null);
  }
}
