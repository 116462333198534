<div class="container" [class]="paddingClass">
  <h2 class="equis-hero-semibold" *ngIf="data?.title">{{ data.title }}</h2>

  <ng-container [ngSwitch]="sourceType">
    <ng-container *ngSwitchCase="'tiktok'"
      ><iframe
        class="tiktok"
        [src]="sanitizer.bypassSecurityTrustResourceUrl(data.embed)"
        frameborder="0"
        scrolling="no"
      ></iframe
    ></ng-container>

    <ng-container *ngSwitchDefault>
      <iframe
        [src]="
          sanitizer.bypassSecurityTrustResourceUrl(
            data.embed + '?modestbranding=1&enablejsapi=1&feature=oembed&origin=' + origin
          )
        "
        [title]="data.contentIdentifier"
        allow="autoplay"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe
    ></ng-container>
  </ng-container>
</div>
