import { Component, Input } from '@angular/core';

import { SectionService } from '@/services/section.service';

import { IBlog } from './blog.types';

@Component({
  selector: 'nx-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  providers: [SectionService],
})
export class BlogComponent implements IBlog {
  constructor(section: SectionService) {
    section.sectionName = 'blog';
  }

  // REQUIRED INPUTS
  @Input() data!: IBlog['data'];

  get backgroudImageUrl() {
    const url = this.data.backgroundImage?.fields.file?.url;
    return `${url}?q=85&fm=webp`;
  }
}
