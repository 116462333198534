export const environment = {
  production: false,
  HOST_URL: 'https://e1-www.naranjax.com',
  PORT: 80,
  BFF_URL: 'https://e1-webnaranja.backendnaranja.com/bff-general',
  BFF_REMOVE_ACCOUNT_URL:
    'https://api.new-dev.naranja.dev/user-manager-bff/public/remove-account',
  AMPLITUDE_EXPERIMENT_URL: 'https://api.lab.amplitude.com/v1/vardata',
  AMPLITUDE_ANALYTICS_URL: 'https://api2.amplitude.com/2/httpapi',
  BRANCHES_URL: 'https://desaservices.apinaranja.com/branches-ms',
  caches: {
    memoryConfig: {
      max: 100,
      ttl: 15 * 1000,
    },
    redisConfig: null,
  },
  redisConfig: {
    rootNodes: [
      {
        url: 'redis://martech-be-integrations.sgf5lu.clustercfg.use1.cache.amazonaws.com:6379',
      },
    ],
    ttl: 15 * 1000,
  },
};
