import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableComponent } from './table.component';
import CtaSectionModule from '../cta-section/cta-section.module';

@NgModule({
  declarations: [TableComponent],
  imports: [CommonModule, CtaSectionModule],
  exports: [TableComponent],
})
export default class TableModule {
  public static component = TableComponent;
}
