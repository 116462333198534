<div class="container wrapper">
  <div class="container--desktop">
    <caption>
      {{
        data.contentIdentifier
      }}
    </caption>
    <div class="table-scroll">
      <table [ngClass]="{ 'table-scroll--none': data.heads.length < 6 }">
        <thead>
          <tr>
            <ng-container *ngFor="let head of data.heads">
              <th scope="col">{{ head }}</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of data.rows; let i = index">
            <tr>
              <ng-container
                *ngFor="let value of data.rows[i].fields.values; let i = index"
              >
                <td [attr.data-label]="data.heads[i]">{{ value }}</td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <p class="caption-text" *ngIf="data.caption">
      {{ data.caption }}
    </p>
    <nx-cta-section [data]="data" *ngIf="data.cta"></nx-cta-section>
  </div>

  <img
    class="img-calendar"
    src="/assets/images/misc/calendario.svg"
    alt="Calendario"
  />
  <img class="img-ticket" src="/assets/images/misc/billete.svg" alt="Ticket" />
  <img class="img-coin" src="/assets/images/misc/monedas.svg" alt="Monedas" />
</div>
