import { Component, Input, OnInit } from '@angular/core';

import { PlatformService } from '@/services/platform.service';
import { IBranch } from '../branches-map.types';

@Component({
  selector: 'nx-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {
  constructor(public platform: PlatformService) {}
  // REQUIRED INPUTS
  @Input() data!: IBranch;
  @Input() terminalFilter!: string;

  // PROPERTIES
  terminalTag!: string;
  private selfManagementSectorProductId = '1';
  get isMobile() {
    return this.platform.isMobile;
  }

  // HOOKS
  ngOnInit() {
    this.terminalTag = `Con ${
      this.terminalFilter.toLowerCase() ?? 'sector de autogestión'
    }`;
  }

  public get getProducts() {
    return this.data.products.filter(
      (product) =>
        product?.prod_id !== this.selfManagementSectorProductId &&
        product?.prod_borrado === '0'
    );
  }

  public get getBadget() {
    return this.data.products.find(
      (product) =>
        product?.prod_id === this.selfManagementSectorProductId &&
        product?.prod_borrado === '0' &&
        product?.active
    );
  }
}
