<div
  class="carousel"
  [ngClass]="{
    'has-quicklink': isQuicklinkFields(data.content[0].fields),
    'has-server-quicklink': platform.isServer
  }"
>
  <!-- CAROUSEL / SLIDER -->
  <swiper-container
    [ngStyle]="{ 'display:none': platform.isServer }"
    class="carousel-container"
    init="false"
    #swiperRef
    *ngIf="isTextImageFields(data.content[0].fields)"
  >
    <ng-container *ngIf="!platform.isServer; else defaultSlider">
      <swiper-slide *ngFor="let item of data.content">
        <nx-component-injector [component]="item"></nx-component-injector>
      </swiper-slide>
    </ng-container>
    <ng-template #defaultSlider>
      <nx-component-injector
        [component]="data.content[0]"
      ></nx-component-injector>
      <div
        class="pagination-server swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"
      >
        <span
          class="swiper-pagination-bullet swiper-pagination-bullet-active"
          part="bullet-active"
        ></span>
        <span class="swiper-pagination-bullet" part="bullet"></span
        ><span class="swiper-pagination-bullet" part="bullet"></span>
      </div>
    </ng-template>
  </swiper-container>

  <swiper-container
    class="carousel-container carousel-quicklink"
    [ngClass]="{
      'carousel-container--server': platform.isServer,
      'server-carousel-quicklink': platform.isServer
    }"
    init="false"
    #swiperRef
    *ngIf="isQuicklinkFields(data.content[0].fields)"
  >
    <swiper-slide *ngFor="let item of data.content" class="carousel-slide">
      <nx-component-injector [component]="item"></nx-component-injector>
    </swiper-slide>
  </swiper-container>

  <!-- PAGINATION -->
  <div
    *ngIf="data.showPagination && !platform.isServer"
    class="pagination"
    #paginationRef
  ></div>

  <!-- NAVIGATION -->
  <div
    class="navigation"
    [ngClass]="{ quicklinks: isQuicklinkFields(data.content[0].fields) && data.content.length <= 5 }"
  >
    <div
      class="carousel-previous navigation-control"
      #prevRef
      (click)="pushNavigation('prev')"
    >
      <div class="icon">
        <div class="icon-angle-left size-24 colored-icon"></div>
      </div>
    </div>
    <div
      class="carousel-next navigation-control"
      #nextRef
      (click)="pushNavigation('next')"
    >
      <div class="icon">
        <div class="icon-angle-right size-24 colored-icon"></div>
      </div>
    </div>
  </div>
</div>
